import React from "react";
import Cookies from "js-cookie";

const ClaimantName = ({ filteredData, claimantnames, shouldHideComponent1, version }) => {
  const userRole = Cookies.get("userRole");
  return (
    <div>
      <div className={`${userRole === "claimant" ? "headerName1" : version === "V2" ? "headerName2" : "headerName"}`}>
        <div>
          <label className="" style={{ width: "70px" }}>
            CL.NAME
          </label>
          :
          {shouldHideComponent1 ? (
            <span className="headerlabel">
              &ensp;
              <span>{claimantnames?.ocf_firstname ? claimantnames?.ocf_firstname : ""}</span>&ensp;
              <span>{claimantnames?.ocf_lastname ? claimantnames?.ocf_lastname : ""}</span>
            </span>
          ) : (
            <span className="headerlabel">
              &ensp;
              <span>{filteredData.length > 0 ? filteredData[0].first_name : ""}</span>&ensp;
              <span>{filteredData.length > 0 ? filteredData[0].last_name : ""}</span>
            </span>
          )}
        </div>
        <div>
          <label style={{ width: "70px" }}>DOA</label>:&ensp;
          {shouldHideComponent1 ? (
            <span className="headerlabel">{claimantnames?.ocf_accidentdate_txt ? claimantnames?.ocf_accidentdate_txt : ""}</span>
          ) : (
            <span className="headerlabel">{filteredData.length > 0 ? filteredData[0].DOA : ""}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimantName;
